<template>
  <div class="page-root">
    <page-title color="green">
      <template v-slot:right>結果</template>
    </page-title>

    <diagnosis-result :is-adhd="isADHD" />

    <div class="start-self-diagnosis" v-if="isADHD">
      <div class="doctor-comment">
        <doctor-flex-box>
          続けて、さらに詳しくADHDの特性に該当するかのチェックができます。
        </doctor-flex-box>
      </div>

      <div class="explanation">
        このチェック結果を持って病院を受信すると、医師が診察時に役立つ情報になります。
      </div>

      <div class="annotation">
        ※セルフ問診は、ご自身の確認用ではありません
      </div>

      <div class="button-block">
        <interview-start-button />
      </div>

      <div class="separate">
        <hr />
      </div>

      <div class="question">
        <div class="title">
          ADHDかもしれないと思ったら？
        </div>

        <div class="description">
          ADHDの方はその特性により、生きづらさを感じている方もいらっしゃるかもしれません。医師や心理士は、その解消方法を一緒に考えていきます。ADHDかもしれないと思い、生きづらさを感じられているなら、
          <span>
            一人で抱え込まずに医師に相談してみませんか？
          </span>
        </div>

        <div class="img">
          <img alt="logo" src="@/assets/adhd/treatment-process.png" />
        </div>

        <div class="start-button-help">
          セルフ問診で医師への相談をスムーズに
        </div>

        <div class="start-button">
          <interview-start-button />
        </div>

        <ul class="link-list">
          <li>
            <a href="https://www.qlifeweb.jp/adultdd/?utm_source=askdoctors&utm_medium=referral&utm_campaign=adultddarticles&utm_content=selfcheck">
              セルフ問診なしで医療機関を探す
            </a>
          </li>
          <li>
            <a
              @click="sendGa('click', 'adhd', 'first-complete-and-ask-qa')"
              :href="askQAUrl"
            >
              セルフ問診なしでネットで相談する
            </a>
          </li>
        </ul>

        <div class="link-annotation">
          ※セルフ問診後に、医師へ相談することができます
        </div>

        <div class="may-become">
          <may-adhd-box />
        </div>
      </div>
    </div>
    <div class="start-self-diagnosis" v-else>
      <div class="explanation">
        <div class="description">
          このチェックリストは、ADHDの特性があるかどうかを確かめるものであり、診断結果を表すものではありません。ADHDかもしれないと思い、生きづらさを感じられているなら、
          <span>一人で抱え込まずに医師に相談してみませんか？</span>
        </div>
        <ul class="link-list">
          <li>
            <a href="https://www.qlifeweb.jp/adultdd/?utm_source=askdoctors&utm_medium=referral&utm_campaign=adultddarticles&utm_content=selfcheck">
              ADHDを相談可能な病院・医療機関を探す
            </a>
          </li>
          <li>
            <a
              @click="sendGa('click', 'adhd', 'first-complete-and-ask-qa')"
              :href="askQAUrl"
            >
              ネットで医師に相談する
            </a>
          </li>
        </ul>
      </div>
    </div>

    <adhd-experience-box />

    <div class="approch" v-if="!isADHD">
      <div class="title">
        日常の困りごと別の対処法
      </div>
      <div class="description">
        日常生活で生きづらさを感じる場合のシーン別対処法をご紹介します。
      </div>

      <a href="https://www.askdoctors.jp/articles/201798" target="_blank">
        <button class="border-green">
          <div class="display-flex flex-justify-space-between flex-align-center content">
            <div class="content-img">
              <img src="@/assets/adhd/business-icon.png" />
            </div>
            <div class="content-text">
              <div>
                集中力が続かない
              </div>
              <div>
                仕事の困りごと編
              </div>
            </div>
            <div>
              <v-icon color="#28c8be">chevron_right</v-icon>
            </div>
          </div>
        </button>
      </a>

      <a href="https://www.askdoctors.jp/articles/201799" target="_blank">
        <button class="border-green">
          <div class="display-flex flex-justify-space-between flex-align-center content">
            <div class="content-img">
              <img src="@/assets/adhd/heart-icon.png" />
            </div>
            <div class="content-text">
              <div>
                余計な一言で後悔
              </div>
              <div>
                感情面の困りごと編
              </div>
            </div>
            <div>
              <v-icon color="#28c8be">chevron_right</v-icon>
            </div>
          </div>
        </button>
      </a>

      <a href="https://www.askdoctors.jp/articles/201800" target="_blank">
        <button class="border-green">
          <div class="display-flex flex-justify-space-between flex-align-center content">
            <div class="content-img">
              <img src="@/assets/adhd/home-icon.png" />
            </div>
            <div class="content-text">
              <div>
                すぐものを無くす
              </div>
              <div>
                家の中での困りごと編
              </div>
            </div>
            <div>
              <v-icon color="#28c8be">chevron_right</v-icon>
            </div>
          </div>
        </button>
      </a>

    </div>

    <div class="know-more" v-if="!isADHD">
      <div class="title">
        ADHDについて詳しく知る
      </div>
      <hr />

      <a href="https://www.askdoctors.jp/articles/201794" target="_blank">
        <div class="display-flex flex-justify-center case">
          <div class="img">
            <img alt="logo" src="@/assets/adhd/articles_201794.png" />
          </div>
          <div class="text">
            <div class="title">
              ADHDって何？
            </div>
            <div class="description">
              ADHDがどのような疾患なのかや、特徴的な症状について解説します。
            </div>
          </div>
          <div class="chevron-right">
            <v-icon color="#28C8BE">chevron_right</v-icon>
          </div>
        </div>
      </a>
      <hr />
      <a href="https://www.askdoctors.jp/articles/201795" target="_blank">
        <div class="display-flex flex-justify-center case">
          <div class="img">
            <img alt="logo" src="@/assets/adhd/articles_201795.png" />
          </div>
          <div class="text">
            <div class="title">
              ADHDの症状
            </div>
            <div class="description">
              大人のADHD患者さんの主な症状を、イラストを交えて解説します。
            </div>
          </div>
          <div class="chevron-right">
            <v-icon color="#28C8BE">chevron_right</v-icon>
          </div>
        </div>
      </a>
      <hr />
    </div>

    <div class="ready">
      <p v-if="isADHD">
        <b>
          まずは受診の準備を始めてみませんか？
        </b>
      </p>
      <div class="start-button" v-if="isADHD">
        <interview-start-button />
      </div>
      <div class="special">
        <adhd-special-page-link-button />
      </div>
    </div>

    <div class="separate">
      <hr />
    </div>

    <div class="main-footer">
      <div class="button-block">
        <link-button link="AdhdAnswerResult" color="green" :is-blank="true">
          自分の回答結果を確認する
        </link-button>
      </div>

      <div class="button-block">
        <link-button link="AdhdFirstScreening">
          セルフチェックを再度行う
        </link-button>
      </div>
    </div>
    <ProgressModal :is-modal-active="isSaveResult" />
  </div>
</template>

<script>
import Vue from "vue";
import rootStore from "@/store";
import { partAQuestions } from "@/data/adhd";
import { urlMixin } from "@/mixin/adhd";
import DiagnosisResult from "@/components/adhd/DiagnosisResult";
import PageTitle from "@/components/adhd/PageTitle";
import LinkButton from "@/components/adhd/LinkButton";
import ProgressModal from "@/components/adhd/ProgressModal";
import InterviewStartButton from "@/components/adhd/InterviewStartButton";
import DoctorFlexBox from "@/components/adhd/DoctorFlexBox";
import MayAdhdBox from "@/components/adhd/MayAdhdBox";
import AdhdExperienceBox from "@/components/adhd/AdhdExperienceBox";
import AdhdSpecialPageLinkButton from "@/components/adhd/AdhdSpecialPageLinkButton";
const state = rootStore.state;
export default Vue.extend({
  name: "AdhdFirstResult",
  head: {
    title: {
      inner: "かんたんADHDセルフチェック"
    }
  },
  mixins: [urlMixin],
  data: function() {
    return {
      questions: partAQuestions(),
      answers: state.adhd.screeningAnswersApart,
      isSaveResult: true
    };
  },
  components: {
    DiagnosisResult,
    PageTitle,
    LinkButton,
    ProgressModal,
    InterviewStartButton,
    DoctorFlexBox,
    MayAdhdBox,
    AdhdExperienceBox,
    AdhdSpecialPageLinkButton
  },
  beforeCreate: function() {
    const params = {
      answer: {
        content: {
          version: "1.0",
          a: state.adhd.screeningAnswersApart
        }
      }
    };
    this.axios
      .post(
        process.env.VUE_APP_API_BASE_URL +
          "v2/api/self_checker/adult_adhd_answer",
        params,
        { withCredentials: true }
      )
      .then(res => {
        this.$store.commit("adhd/changeAnswerId", {
          answerId: res.data.id
        });
        this.isSaveResult = false;
      })
      .catch(() => {
        this.$router.push({
          name: "AdhdError",
          params: { backLink: "AdhdFirstScreening" }
        });
      });
  },
  created() {
    this.sendGa("click", "adhd", "complete-first-screening");
  },
  computed: {
    isADHD() {
      const point = this.answers.reduce(
        (sum, element, idx) => sum + this.questions[idx].answerPoints[element],
        0
      );
      return point >= 4;
    }
  },
  methods: {
    async onNext() {
      await this.$router.push("/adhd/second_screening");
    }
  }
});
</script>

<style scope>
@import "../../assets/style/adhd.css";
.start-self-diagnosis hr {
  margin: 0px 16px;
}

.separate hr {
  border-top: 8px solid #f0f3f3;
  margin: 0px;
}

.start-self-diagnosis .doctor-comment {
  margin: 32px 16px 0px 16px;
}

.start-self-diagnosis a {
  text-decoration: underline;
  color: #324646;
}

.start-self-diagnosis .explanation {
  margin: 24px 16px 0px 16px;
  font-size: 15px;
  text-align: left;
}

.start-self-diagnosis .annotation {
  margin: 12px 16px 0px 16px;
  font-size: 13px;
  text-align: left;
}

.start-self-diagnosis .separate {
  padding: 16px 0px;
}

.start-self-diagnosis .question {
  margin: 32px 16px 0px 16px;
}
.start-self-diagnosis .question .title {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #324646;
}

.start-self-diagnosis .question .description {
  padding-top: 16px;
  font-size: 15px;
  text-align: left;
}

.start-self-diagnosis .question .description span {
  font-weight: 700;
}

.start-self-diagnosis .question .img {
  margin-top: 24px;
}

.start-self-diagnosis .question .start-button-help {
  margin-top: 36px;
  font-size: 16px;
  font-weight: 700;
}

.start-self-diagnosis .question .start-button {
  margin-top: 16px;
}

.start-self-diagnosis .question .link-list,
.start-self-diagnosis .explanation .link-list {
  margin: 16px 16px 0px 24px;
  list-style-type: disc;
  text-align: left;
}
.start-self-diagnosis .question .link-list li,
.start-self-diagnosis .explanation .link-list li {
  padding-top: 8px;
}
.start-self-diagnosis .question .link-annotation,
.start-self-diagnosis .explanation .link-annotation {
  margin-top: 16px;
  font-size: 14px;
  text-align: left;
}
.may-become {
  margin-top: 32px;
}

.ready {
  margin: 0px 16px 40px 16px;
}
.ready .start-button {
  margin-top: 16px;
}
.ready .special {
  padding-top: 36px;
}
.main-footer {
  padding-top: 24px;
}

.know-more {
  margin: 32px 16px 0px 16px;
}
.start-self-diagnosis a {
  color: #324646;
}

.know-more hr {
  margin: 0px;
}
.know-more a {
  color: #324646;
}
.know-more .title {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 0px 0px 16px 0px;
}
.know-more .case {
  padding: 16px 0px;
}

.know-more .case .img {
  width: 100px;
  height: 100px;
  background-color: #efefef;
  margin: 0px;
}

.know-more .case .text {
  flex: 1;
  margin: 0px 16px;
}
.know-more .case .text .title {
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  margin: 0px;
}
.know-more .case .text .description {
  padding-top: 8px;
  font-size: 13px;
  text-align: left;
}
.know-more .case .chevron-right {
  margin: auto;
}

.approch {
  margin: 32px 16px;
}
.approch .title {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}
.approch .description {
  padding-top:16px;
  font-size: 15px;
  text-align: left;
}
.approch a {
  color: #324646;
}
.approch a button {
  border-radius: 8px;
  margin-top: 16px;
  width: 100%;
}
.approch a button .content {
  margin: 16px 16px;
}
.approch a button .content .content-img {
  width: 32px;
  height: 32px;
}
.approch a button .content .content-text {
  padding-left:16px;
  text-align:left;
  flex-grow:2;
}
.approch a button .content .content-text div:first-child {
  font-size: 18px;
  font-weight: 700;
}
.approch a button .content .content-text div:nth-child(2) {
  font-size: 13px;
}

.button-block {
  margin: 16px;
}

@media (hover: hover) {
  .start-self-diagnosis a:hover {
    text-decoration: none;
  }
  .know-more .case:hover {
    background-color: #f0fafa;
  }
}
@media (hover: none) {
  .start-self-diagnosis a:active {
    text-decoration: none;
  }
  .know-more .case:active {
    background-color: #f0fafa;
  }
}
</style>
