<template>
  <link-button link="AdhdSecondScreening" background-color="blue">
    <div class="display-flex flex-align-center flex-justify-center">
      <div class="free-icon">無料</div>
      <div class="text">セルフ問診を始める</div>
    </div>
  </link-button>
</template>

<script>
import LinkButton from "@/components/adhd/LinkButton";
export default {
  name: "InterviewStartButton",
  components: { LinkButton }
};
</script>

<style scoped>
@import "../../assets/style/adhd.css";

button .free-icon {
  padding: 4px 12px;
  background-color: #ffe600;
  color: #0bbdd6;
  font-size: 16px;
  font-weight: 700;
  border-radius: 3px;
}
button .text {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
}
</style>
